import React from 'react';

class Footer extends React.Component {

    render () {
        return(
        <div className="Footer">
        <h1 className="Footer-Text">Created by Bernardo de Barros Barreto Costa Machado</h1>
        </div>
        );
    }
}

export default Footer;